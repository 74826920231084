<template>
  <div @click.stop="close">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h2 class="modal-title">เพิ่มรายการตัดสินค้าทั้งหมด</h2>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
              id="closeBtn"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body">
            <div class="row d-flex justify-content-center mb-4">
              <label class="col-sm-10 text-start">เลขที่เอกสาร</label>
              <div class="col-sm-10">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
            <div class="row d-flex justify-content-center text-start mb-4">
              <label for="input1  form-label" class="col-sm-10"
                >ค้นหาสินค้า</label
              >
              <div class="col-sm-5">
                <input
                  v-model="searchProduct"
                  type="text"
                  class="form-control"
                  id="input1"
                  :style="
                    isSubmit
                      ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                      : ''
                  "
                />
              </div>

              <div class="col-sm-3 mb-3">
                <div
                  class="
                    form-check
                    form-check-inline
                    form-check-custom
                    form-check-solid
                    mt-3
                  "
                >
                  <input
                    v-model="searchBy"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="code"
                    id="flexRadioChecked"
                  />
                  <label
                    class="form-check-label me-4"
                    for="flexRadioChecked"
                    :style="isSubmit ? 'color: black' : ''"
                    >Item no.</label
                  >
                  <input
                    v-model="searchBy"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="barcode"
                    id="flexRadioChecked2"
                    checked="checked"
                  />
                  <label
                    class="form-check-label"
                    for="flexRadioChecked"
                    :style="isSubmit ? 'color: black' : ''"
                    >Barcode</label
                  >
                </div>
              </div>

              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-sm btn-light-primary"
                  style="font-size: 14px; font-weight: bold; width: 100%"
                  width="100%"
                  @click="search"
                >
                  ค้นหา
                </button>
              </div>
            </div>

            <div class="row d-flex justify-content-center text-start mb-4">
              <label class="col-sm-10">Item no</label>
              <div class="col-sm-10">
                <input
                  v-model="form.product_code"
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
            <div class="row d-flex justify-content-center text-start mb-4">
              <label class="col-sm-10">Item name</label>
              <div class="col-sm-10">
                <input
                  v-model="form.product_name"
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
            <div class="row d-flex justify-content-center text-start mb-4">
              <label class="col-sm-10">Barcode</label>
              <div class="col-sm-10">
                <input
                  v-model="form.product_barcode"
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>

            <div class="row d-flex justify-content-center text-start">
              <label class="col-sm-10">ราคา</label>
              <div class="col-sm-10">
                <input
                  v-model="form.item_price"
                  type="text"
                  class="form-control mb-4"
                  id="inputGroupFile01"
                />
              </div>
            </div>

            <div class="row d-flex justify-content-center text-start">
              <label class="col-sm-10">จำนวนหน่วย</label>
              <div class="col-sm-10">
                <input
                  v-model="form.item_amt"
                  type="text"
                  class="form-control mb-4"
                  id="inputGroupFile01"
                />
              </div>
            </div>
          </div>

          <hr class="pt-0 mt-0" style="color: LightGrey" />

          <footer class="mt-2 mb-6 ms-7 row d-flex justify-content-center">
            <div
              class="card-toolbar ps-0 col-sm-10 d-flex justify-content-start"
            >
              <button
                @click="submit"
                type="button"
                class="btn btn-primary me-3"
                data-bs-dismiss="modal"
              >
                บันทึก
              </button>
              <button
                @click="close"
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                ยกเลิก
              </button>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

export default {
  props: {
    dialogAddItem: Boolean,
    dataEditItem: Object,
  },
  data: () => ({
    isSubmit: false,

    searchBy: "barcode",
    searchProduct: "",

    form: {
      //new
      selectMainProduct: "",
      selectSubProduct: "",

      product_name: "",
      product_desc: "",
      product_barcode: "",
      product_pog: "",
      product_cost_price: "",
      product_wholesale_price: "",
      product_retail_price: "",
      product_promotion_price: "",
      product_min_stock: "",
      product_max_stock: "",
      product_bring_qty: "",
      status: "0",
      approved_by: "",
      product_expire: "",

      item_amt: "",
      item_discount: "",
      item_price: "",

      //old
      itemNo: "",
      itemDetail: "",
      barcode: "",
      accountingPeriod: "",
      docNumber: "",
      amount: "",
      pricePerUnit: "",
      discountPerUnit: "",
      vat: "",
      receiveDate: "",
      consignee: "",
      warehouseTo: "",
    },
  }),
  watch: {
    dialogAddItem(val) {
      if (val) {
        document.getElementById("button-open-dialog").click();
        if (this.dataEditItem) {
          this.form = this.dataEditItem;
          this.searchProduct = this.dataEditItem.product_barcode;
        }
      }
    },
  },
  methods: {
    async submit() {
      if (
        Object.keys(this.dataEditItem).length === 0 &&
        this.dataEditItem.constructor === Object
      ) {
        await this.$emit("submitAddItem", this.form);
      } else {
        await this.$emit("submitEditItem", this.form);
      }
      this.clearForm();
      this.close();
    },
    close() {
      this.$emit("closeDialogAddItem");
    },
    async search() {
      const companyId = localStorage.getItem("companyId");
      if (this.searchBy === "code") {
        await this.searchByCode(companyId, this.searchProduct);
      }
      if (this.searchBy === "barcode") {
        await this.searchByBarcode(companyId, this.searchProduct);
      }
    },
    async searchByCode(companyId, productCode) {
      let responseData = [];
      try {
        responseData = await whApi.product.searchByCode(companyId, productCode);
      } catch (error) {
        console.log(error);
      }
      if (responseData.data.length > 0) {
        if (responseData.data[0].status !== "1") {
          Swal.fire({
            icon: "warning",
            title: `ค้นหาสำเร็จ !`,
            text: `สินค้า Code : ${productCode} ยังไม่ได้รับการอนุมัติ`,
            confirmButtonText: "ตกลง",
          });
        } else {
          this.form = {
            ...this.form,
            ...responseData.data[0],
            item_price: responseData.data[0].product_cost_price,
          };
        }
      } else {
        Swal.fire({
          icon: "error",
          title: `ค้นหาไม่สำเร็จ !`,
          text: `ไม่พบสินค้า Item no. : ${productCode}`,
          confirmButtonText: "ตกลง",
        });
      }
    },
    async searchByBarcode(companyId, productBarcode) {
      let responseData = [];
      try {
        responseData = await whApi.product.searchByBarcode(
          companyId,
          productBarcode
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.data.length > 0) {
        if (responseData.data[0].status !== "1") {
          Swal.fire({
            icon: "warning",
            title: `ค้นหาสำเร็จ !`,
            text: `สินค้า Barcode : ${productBarcode} ยังไม่ได้รับการอนุมัติ`,
            confirmButtonText: "ตกลง",
          });
        } else {
          this.form = {
            ...this.form,
            ...responseData.data[0],
            item_price: responseData.data[0].product_cost_price,
          };
        }
      } else {
        Swal.fire({
          icon: "error",
          title: `ค้นหาไม่สำเร็จ !`,
          text: `ไม่พบสินค้า Barcode : ${productBarcode}`,
          confirmButtonText: "ตกลง",
        });
      }
    },
    clearForm() {
      this.searchProduct = "";
      this.form = {
        //new
        selectMainProduct: "",
        selectSubProduct: "",

        product_name: "",
        product_desc: "",
        product_barcode: "",
        product_pog: "",
        product_cost_price: "",
        product_wholesale_price: "",
        product_retail_price: "",
        product_promotion_price: "",
        product_min_stock: "",
        product_max_stock: "",
        product_bring_qty: "",
        status: "0",
        approved_by: "",
        product_expire: "",

        //old
        itemNo: "",
        itemDetail: "",
        barcode: "",
        accountingPeriod: "",
        docNumber: "",
        amount: "",
        pricePerUnit: "",
        discountPerUnit: "",
        vat: "",
        receiveDate: "",
        consignee: "",
        warehouseTo: "",
      };
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
